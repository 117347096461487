@import "~antd/lib/style/themes/default.less";

.ant-layout-content {
  display: flex;
  flex-direction: column;
}

.ant-modal-header {
  background: @primary-color;
}
.ant-modal-header .ant-modal-title,
.ant-modal-content .ant-modal-close-x {
  color: white
}

@primary-color: #3dcd58;@layout-header-background: #3dcd58;@border-radius-base: 8px;