@import "~antd/lib/style/themes/default.less";

.mySiteContainer {
  align-items: center;
  justify-content: center;
  & > div {
    width: 95%;
    max-width: 600px;
  }
}
.mainLogo {
  height: 150px;
  margin-bottom: 80px;
}

.sider {
  height: calc(100vh - @layout-header-height);
  position: fixed;
  top: @layout-header-height;
  left: 0;
  overflow: auto;
  // & > :global(.ant-layout-sider-children) {
  //   height: auto;
  // }
}
.searchFieldWrapper {
  padding: 12px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  border-bottom: 1px solid #ececec;
  border-right: 1px solid #ececec;
}
.roomTitle {
  margin: 0 !important;
}
.container {
  padding: 24px;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.historyDrawerHeader {
  padding: 0 !important;
  margin-bottom: 40px !important;
}

.roomMenuItem {
  line-height: 23px !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start !important;
}
.roomMenuItem p {
  font-size: 0.8em;
  margin: 0;
}

@primary-color: #3dcd58;@layout-header-background: #3dcd58;@border-radius-base: 8px;