@import "~antd/lib/style/themes/default.less";

.roomHeader {
  border-radius: @border-radius-base;
  box-shadow: 0px 12px 20px 0px #00000014;
  margin-bottom: 20px;
}

.workplaces {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 15px;
}
.wpActions {
  margin-bottom: 20px;
  display: flex;
  padding: 15px 0;
  & > * {
    margin-right: 15px;
  }
}

@primary-color: #3dcd58;@layout-header-background: #3dcd58;@border-radius-base: 8px;