@import "~antd/lib/style/themes/default.less";

.mainContainer {
  min-height: 100vh;
}
.brandHeader {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  color: @primary-color;
  img {
    max-width: 128px;
    max-height: 48px;
  }
  .brandWPE {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    div {
      line-height: 1.5em;
    }
  }
}
.mainHeader {
  position: sticky;
  top: @layout-header-height;
  z-index: 2;
  box-shadow: 0px 5px 20px #00000012;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}
.mainTitle {
  margin: 0;
  margin-right: 30px;
  color: white;
}
:global(.ant-menu).mainMenu {
  background: transparent;
  color: white;
  &:global(.ant-menu-horizontal) {
    & > :global(.ant-menu-item):hover,
    & > :global(.ant-menu-submenu):hover,
    & > :global(.ant-menu-item-active),
    & > :global(.ant-menu-submenu-active),
    & > :global(.ant-menu-item-open),
    & > :global(.ant-menu-submenu-open),
    & > :global(.ant-menu-item-selected),
    & > :global(.ant-menu-submenu-selected) {
      color: white;
      border-color: white;
    }
    & > :global(.ant-menu-item-selected)::after {
      border-color: white;
    }
  }
}

@primary-color: #3dcd58;@layout-header-background: #3dcd58;@border-radius-base: 8px;