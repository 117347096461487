@import "~antd/lib/style/themes/default.less";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  background-color: #e3e3e3;
  padding: 5px;
}

.txtPrimary {
  color: @primary-color;
}
.lightHeader {
  background-color: white;
}
.grow {
  flex-grow: 1;
}
.helpText {
  color: @text-color-secondary;
  text-align: justify;
}
.helpText-small:extend(.helpText) {
  font-size: 0.8em;
}
@primary-color: #3dcd58;@layout-header-background: #3dcd58;@border-radius-base: 8px;